import lodash from "lodash";
import Products from "../../../../common/models/Product";
import ProductAir, {
  FlightSegmentEndpoint,
} from "../../../../common/models/ProductAir";
import {
  Location,
  SalesSummaryItem,
} from "../../../../common/models/ProductCar";
import ProductHotel from "../../../../common/models/ProductHotel";
import Trip from "../../../../common/models/Trip";
import TripProduct from "../../../../common/models/TripProduct";
import { selectFeatureToggles } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import Store from "../../../../store/Store";
import {
  FeatureToggleDefinition,
  TRIP_TYPE_TIME,
} from "../../../../utils/constants";
import HostSettings from "../../../../utils/host.settings";
import AirForRedirection from "../../models/AirForRedirection";
import CarForRedirection from "../../models/CarForRedirection";
import HotelForRedirection from "../../models/HotelForRedirection";
import RailForRedirection from "../../models/RailForRedirection";
import { DeeplinkOptions } from "./DeeplinkOptions";

export const getTrainLocation = (loc: Location | undefined) => {
  if (loc) {
    return loc.name;
  }
  return "";
};

export const getTrainProviderCode = (loc: Location | undefined) => {
  if (loc?.providerCode) {
    return loc.providerCode;
  }
  return "";
};

export const getTrainProviderStationCode = (loc: Location | undefined) => {
  if (loc?.providerStationCode) {
    return loc.providerStationCode;
  }
  return "";
};

export const getLocationName = (loc: SalesSummaryItem) => {
  if (loc.location?.name) {
    return loc.location?.name;
  }
  return "";
};

export const getCarOfficeCode = (name: any) => {
  if (!name) {
    return "";
  }
  const re = /\(([^)]+)\)/;
  const match = name.match(re);
  return match ? match[match.length - 1] : "";
};

export const getLocationCode = (loc: SalesSummaryItem) => {
  if (loc.location?.iataCode) {
    return loc.location?.iataCode;
  }
  return "";
};

export const getHotelLocationCode = (loc: Location | undefined) => {
  if (loc?.iataCode) {
    return loc.iataCode;
  }
  if (loc?.cityName) {
    return loc.cityName;
  }
  return "";
};

export const getLatitude = (loc: SalesSummaryItem) => {
  if (loc.location?.geoCode.latitude) {
    return loc.location?.geoCode.latitude;
  }
  return 0;
};

export const getLongitude = (loc: SalesSummaryItem) => {
  if (loc.location?.geoCode.longitude) {
    return loc.location?.geoCode.longitude;
  }
  return 0;
};

export const getCountry = (loc: SalesSummaryItem) => {
  if (loc.location?.address.countryCode) {
    return loc.location?.address.countryCode;
  }
  return "";
};
export const getDatetime = (loc: SalesSummaryItem) => {
  if (loc.dateTime) {
    return loc.dateTime;
  }
  return "";
};

export const getPurpose = (purpose: string | undefined) => {
  if (purpose) {
    return purpose;
  }
  return "";
};

export const getFlightDatetime = (segmentEndpoint: FlightSegmentEndpoint) => {
  if (segmentEndpoint.dateTime) {
    return segmentEndpoint.dateTime;
  }
  return "";
};

export const getIataCode = (loc: Location | undefined) =>
  loc?.iataCode ? loc?.iataCode : "";

export const getLocName = (loc: Location | undefined) =>
  loc?.name ? loc?.name : "";

export const getHotelName = (hotel: ProductHotel | undefined) =>
  hotel?.name ? hotel?.name : "";

export const getHotelLatitude = (loc: Location | undefined) => {
  if (loc?.geoCode.latitude) {
    return loc?.geoCode.latitude;
  }
  return 0;
};

export const getHotelLongitude = (loc: Location | undefined) => {
  if (loc?.geoCode.longitude) {
    return loc?.geoCode.longitude;
  }
  return 0;
};

export const getDeepLinkFlight = (
  urlFlight: AirForRedirection,
  deeplinkOptions: DeeplinkOptions
) => {
  if (urlFlight.dateTime.length > 0) {
    const contextFlight = {
      subEntityId: JSON.stringify(urlFlight),
    };
    const isTRIPPUser = deeplinkOptions.isTRIPPUser || false;
    const redirectionTarget = isTRIPPUser ? "cytricweb" : "travel";
    const stringContextFlight = encodeURIComponent(
      JSON.stringify(contextFlight)
    )
      .replace(/:/g, "%3A")
      .replace(/,/g, "%2C");

    return `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/${redirectionTarget}?context=${stringContextFlight}`;
  }
  return "";
};

export const getDeepLinkRail = (
  urlRail: RailForRedirection,
  deepLinkOptions: DeeplinkOptions
) => {
  if (urlRail.dateTime.length > 0) {
    const contextRail = {
      subEntityId: JSON.stringify(urlRail),
    };
    const isTRIPPUser = deepLinkOptions.isTRIPPUser || false;
    const redirectionTarget = isTRIPPUser ? "cytricweb" : "travel";
    const stringContextRail = JSON.stringify(contextRail);

    return `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/${redirectionTarget}?context=${encodeURIComponent(stringContextRail)}`;
  }
  return "";
};
export const getDeepLinkCar = (
  urlCar: CarForRedirection,
  deeplinkOptions: DeeplinkOptions
) => {
  if (urlCar.dateTime.length > 0) {
    const contextCar = {
      subEntityId: JSON.stringify(urlCar),
    };
    const isTRIPPUser = deeplinkOptions.isTRIPPUser || false;
    const redirectionTarget = isTRIPPUser ? "cytricweb" : "travel";
    const stringContextCar = JSON.stringify(contextCar);
    return `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/${redirectionTarget}?context=${encodeURIComponent(stringContextCar)}`;
  }
  return "";
};

export const getDeepLinkHotel = (
  urlHotel: HotelForRedirection,
  deepLinkOptions: DeeplinkOptions
) => {
  if (urlHotel.locationCode !== "") {
    const contextHotel = {
      subEntityId: JSON.stringify(urlHotel),
    };
    const isTRIPPUser = deepLinkOptions.isTRIPPUser || false;
    const redirectionTarget = isTRIPPUser ? "cytricweb" : "travel";
    const stringContextHotel = JSON.stringify(contextHotel);
    return `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/${redirectionTarget}?context=${encodeURIComponent(stringContextHotel)}`;
  }
  return "";
};

const pushIfPresent = (element: any, arr: any) => {
  if (element) {
    arr?.push(element);
  }
};

function cloneUrlFeatureBookSameTrain(
  cloneUrl: any,
  element: any,
  isFeatureBookSameTrainEnable: boolean
) {
  if (isFeatureBookSameTrainEnable) {
    const departureStationCode = getTrainProviderStationCode(
      element.Trip_Product_Train.departure
    );
    if (departureStationCode != null) {
      cloneUrl.departureCode.push(departureStationCode);
    }
    const arrivalStationCode = getTrainProviderStationCode(
      element.Trip_Product_Train.arrival
    );
    if (arrivalStationCode != null) {
      cloneUrl.arrivalCode.push(arrivalStationCode);
    }
    const trainProviderCode = getTrainProviderCode(
      element.Trip_Product_Train.arrival
    );
    if (trainProviderCode != null) {
      cloneUrl.providerCode.push(trainProviderCode);
    }
  }
}

function cloneUrlFeatureBookSameCar(
  cloneUrl: any,
  element: any,
  featureRedirectionSameCar: boolean
) {
  if (featureRedirectionSameCar) {
    cloneUrl.carOfficeCode.push(
      getCarOfficeCode(getLocationName(element.Trip_Product_Car.pickup))
    );
    cloneUrl.carOfficeCode.push(
      getCarOfficeCode(getLocationName(element.Trip_Product_Car.dropoff))
    );
  }
}

export const getUrl = (
  segmentType: string,
  url: any,
  element: any,
  flightMatchingData: any = {}
) => {
  const cloneUrl = lodash.cloneDeep(url);
  if (segmentType === "air") {
    let flightsCounter: number = 1;

    const featureToggles = selectFeatureToggles(Store.getState());
    const matchDepartureTimeOnReturnFlightFeature = featureToggles.find(
      (ft) =>
        ft.feature ===
        FeatureToggleDefinition.matchDepartureTimeOnReturnFlight.id
    );
    if (matchDepartureTimeOnReturnFlightFeature?.isActive) {
      const key =
        (element.Trip_Product_Air?.departure.iataCode || "") +
        (element.Trip_Product_Air?.arrival.iataCode || "");
      cloneUrl.dateTime.push(
        getFlightDatetime(
          flightMatchingData[key] === TRIP_TYPE_TIME.departure.code
            ? element.Trip_Product_Air.departure
            : element.Trip_Product_Air.arrival
        )
      );
      cloneUrl.dateTimeType?.push(
        flightMatchingData[key] || TRIP_TYPE_TIME.arrival.code
      );
      cloneUrl.timeWindowInHours?.push(1);
    } else {
      cloneUrl.dateTime.push(
        getFlightDatetime(element.Trip_Product_Air.arrival)
      );
      cloneUrl.dateTimeType?.push(TRIP_TYPE_TIME.arrival.code);
      cloneUrl.timeWindowInHours?.push(1);
    }

    cloneUrl.departure.push(element.Trip_Product_Air.departure.iataCode);
    cloneUrl.arrival.push(element.Trip_Product_Air.arrival.iataCode);

    cloneUrl.basisCode?.push(
      element.Trip_Product_Air.bookingClass?.fareBasis || ""
    );

    pushIfPresent(element.Trip_Product_Air.carrierCode, cloneUrl.airlineCode);
    cloneUrl.airlineNumber?.push(Number(element.Trip_Product_Air.number));
    element.Trip_Product_Air.boundStops?.forEach((stop: any) => {
      pushIfPresent(stop.carrierCode, cloneUrl.airlineCode);
      pushIfPresent(stop.number, cloneUrl.airlineNumber);
      flightsCounter += 1;
    });
    cloneUrl.numberOfFlights?.push(flightsCounter);
  } else if (segmentType === "car") {
    cloneUrl.name.push(getLocationName(element.Trip_Product_Car.pickup));
    cloneUrl.name.push(getLocationName(element.Trip_Product_Car.dropoff));
    cloneUrl.code.push(getLocationCode(element.Trip_Product_Car.pickup));
    cloneUrl.code.push(getLocationCode(element.Trip_Product_Car.dropoff));
    cloneUrl.latitude.push(getLatitude(element.Trip_Product_Car.pickup));
    cloneUrl.latitude.push(getLatitude(element.Trip_Product_Car.dropoff));
    cloneUrl.longitude.push(getLongitude(element.Trip_Product_Car.pickup));
    cloneUrl.longitude.push(getLongitude(element.Trip_Product_Car.dropoff));
    cloneUrl.country.push(getCountry(element.Trip_Product_Car.pickup));
    cloneUrl.country.push(getCountry(element.Trip_Product_Car.dropoff));
    cloneUrl.dateTime.push(getDatetime(element.Trip_Product_Car.pickup));
    cloneUrl.dateTime.push(getDatetime(element.Trip_Product_Car.dropoff));
  } else if (segmentType === "train") {
    cloneUrl.dateTime.push(element.Trip_Product_Train.departureDateTime);
    cloneUrl.departure.push(
      getTrainLocation(element.Trip_Product_Train.departure)
    );
    cloneUrl.arrival.push(getTrainLocation(element.Trip_Product_Train.arrival));
  } else if (segmentType === "hotel") {
    cloneUrl.locationCode = getHotelLocationCode(
      element.Trip_Product_Hotel.location
    );
    cloneUrl.locationName = getLocName(element.Trip_Product_Hotel.location);
    cloneUrl.arrival = element.Trip_Product_Hotel.checkInDate;
    cloneUrl.departure = element.Trip_Product_Hotel.checkOutDate;
    cloneUrl.name = getHotelName(element.Trip_Product_Hotel);
    cloneUrl.latitude = getHotelLatitude(element.Trip_Product_Hotel.location);
    cloneUrl.longitude = getHotelLongitude(element.Trip_Product_Hotel.location);
  }

  return cloneUrl;
};

const processProduct = (
  product: TripProduct,
  urlObjects: any,
  flightMatchingData: any,
  isFeatureBookSameTrainEnable: boolean,
  featureRedirectionSameCar: boolean
) => {
  if (product.Trip_Product_Air) {
    // eslint-disable-next-line no-param-reassign
    urlObjects.urlFlight = getUrl(
      "air",
      urlObjects.urlFlight,
      product,
      flightMatchingData
    );
  }
  if (product.Trip_Product_Train) {
    // eslint-disable-next-line no-param-reassign
    urlObjects.urlRail = getUrl("train", urlObjects.urlRail, product);
    cloneUrlFeatureBookSameTrain(
      urlObjects.urlRail,
      product,
      isFeatureBookSameTrainEnable
    );
  }
  if (product.Trip_Product_Car) {
    // eslint-disable-next-line no-param-reassign
    urlObjects.urlCar = getUrl("car", urlObjects.urlCar, product);
    cloneUrlFeatureBookSameCar(
      urlObjects.urlCar,
      product,
      featureRedirectionSameCar
    );
  }
  if (product.Trip_Product_Hotel) {
    // eslint-disable-next-line no-param-reassign
    urlObjects.urlHotel = getUrl("hotel", urlObjects.urlHotel, product);
  }
};

function isReturnFlight(airProducts: ProductAir[]) {
  if (airProducts && airProducts.length === 2) {
    return (
      (airProducts[0].departure.iataCode === airProducts[1].arrival.iataCode ||
        (airProducts[0].departure.countryCode ===
          airProducts[1].arrival.countryCode &&
          airProducts[0].departure.cityName ===
            airProducts[1].arrival.cityName)) &&
      (airProducts[1].departure.iataCode === airProducts[0].arrival.iataCode ||
        (airProducts[1].departure.countryCode ===
          airProducts[0].arrival.countryCode &&
          airProducts[1].departure.cityName ===
            airProducts[0].arrival.cityName))
    );
  }
  return false;
}

const getMatchTimesData = (airProd: Products) => {
  const airProducts = airProd.products
    .filter(
      (tripProduct): tripProduct is TripProduct =>
        !!tripProduct.Trip_Product_Air
    )
    .map((tripProduct) => tripProduct.Trip_Product_Air)
    .filter((tripProductAir): tripProductAir is ProductAir => !!tripProductAir);
  if (isReturnFlight(airProducts)) {
    return {
      [airProducts[0].departure.iataCode + airProducts[0].arrival.iataCode]:
        TRIP_TYPE_TIME.arrival.code,
      [airProducts[1].departure.iataCode + airProducts[1].arrival.iataCode]:
        TRIP_TYPE_TIME.departure.code,
    };
  }
  return {};
};

export default function GetCardDeeplinksUrls(
  trip: Trip,
  appCatalogId: string,
  deeplinkOptions: DeeplinkOptions
) {
  const isFeatureBookSameTrainEnable =
    deeplinkOptions.isFeatureBookSameTrainEnable || false;
  const featureRedirectionSameCar =
    deeplinkOptions.featureRedirectionSameCar || false;
  const featureAddSharedTripParam =
    deeplinkOptions.featureAddSharedTripParam || false;

  const initUrlFlight: AirForRedirection = {
    segmentType: "Flight",
    numberOfPassengers: 1,
    directFlights: false,
    stopOnStartPage: false,
    departure: new Array<string>(),
    arrival: new Array<string>(),
    dateTime: new Array<string>(),
    dateTimeType: new Array<string>(),
    purpose: getPurpose(trip.purpose),
    airlineCode: new Array<string>(),
    airlineNumber: new Array<number>(),
    basisCode: new Array<string>(),
    numberOfFlights: new Array<number>(),
    timeWindowInHours: new Array<number>(),
    buttonType: "bookFlight",
  } as AirForRedirection;
  const initUrlRail: RailForRedirection = {
    stopOnStartPage: !isFeatureBookSameTrainEnable,
    purpose: getPurpose(trip.purpose),
    directTrains: false,
    segmentType: "Rail",
    dateTime: new Array<string>(),
    departure: new Array<string>(),
    departureCode: new Array<string>(),
    arrival: new Array<string>(),
    arrivalCode: new Array<string>(),
    providerCode: new Array<string>(),
    buttonType: "bookTrain",
  } as RailForRedirection;
  const initUrlCar: CarForRedirection = {
    segmentType: "Car",
    purpose: getPurpose(trip.purpose),
    carDelivery: false,
    carCollection: false,
    stopOnStartPage: !featureRedirectionSameCar,
    code: new Array<string>(),
    name: new Array<string>(),
    latitude: new Array<number>(),
    longitude: new Array<number>(),
    country: new Array<string>(),
    dateTime: new Array<string>(),
    buttonType: "bookCar",
  } as CarForRedirection;
  if (deeplinkOptions.featureRedirectionSameCar) {
    initUrlCar.carOfficeCode = new Array<string>();
  }
  const initUrlHotel: HotelForRedirection = {
    segmentType: "Hotel",
    buttonType: "bookHotel",
    locationCode: "",
    locationName: "",
    arrival: "",
    departure: "",
    name: "",
    latitude: 0,
    longitude: 0,
    purpose: getPurpose(trip.purpose),
  } as HotelForRedirection;

  if (featureAddSharedTripParam) {
    initUrlHotel.isSharedTrip = true;
    initUrlCar.isSharedTrip = true;
    initUrlRail.isSharedTrip = true;
    initUrlFlight.isSharedTrip = true;
  }
  const urlObjects = {
    urlFlight: initUrlFlight,
    urlRail: initUrlRail,
    urlCar: initUrlCar,
    urlHotel: initUrlHotel,
  };
  if (trip.products) {
    const matchTimesData = getMatchTimesData(trip.products[0]);

    trip.products[0].products.forEach((element: TripProduct) => {
      processProduct(
        element,
        urlObjects,
        matchTimesData,
        isFeatureBookSameTrainEnable,
        featureRedirectionSameCar
      );
    });
  }

  const flightDeepLink = getDeepLinkFlight(
    urlObjects.urlFlight,
    deeplinkOptions
  );
  const railDeepLink = getDeepLinkRail(urlObjects.urlRail, deeplinkOptions);
  const carDeepLink = getDeepLinkCar(urlObjects.urlCar, deeplinkOptions);
  const hotelDeepLink = getDeepLinkHotel(urlObjects.urlHotel, deeplinkOptions);

  return { flightDeepLink, railDeepLink, carDeepLink, hotelDeepLink };
}
