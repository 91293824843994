import HandleTravelCytricRedirection from "../../../utils/HandleTravelCytricRedirection";

export default function BookingCytricRedirection(
  subEntityId: string,
  history: any,
  t: any,
  dispatch: any,
  isGptBookButton = false
) {
  const breadcrumbData =
    subEntityId.includes("bookFromTripLogistics") || isGptBookButton
      ? [
          {
            label:
              "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
            url: "/travel",
            translation: true,
          },
          {
            label: "upcoming_trips_dashboard.breadcrumb.planATrip",
            url: "",
            translation: true,
          },
        ]
      : [
          {
            label:
              "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
            url: "/travel",
            translation: true,
          },
          {
            label: "upcoming_trips_dashboard.breadcrumb.joinATrip",
            url: "",
            translation: true,
          },
        ];

  HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

  const context = encodeURIComponent(subEntityId);
  history.push(`/cytricweb?context=${context}&type=travel`);
}
