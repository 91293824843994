import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex, Loader } from "@fluentui/react-northstar";
import { BotUrlDialogInfo, app, dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
  TrackError,
} from "../../common/components/generic-info-component/GenericInfo.model";
import { doLogoutFromTravelTab } from "../../common/components/logout/logout-component";
import { IError } from "../../common/models/Error";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import TripHelper from "../../helpers/TripHelper";
import Store from "../../store/Store";
import { useAppSelector } from "../../store/StoreHooks";
import { isLoggedInSelector } from "../../store/authentication/AuthenticationSelector";
import {
  selectLastUrlDateTimeout,
  selectLastVisitedUrl,
} from "../../store/cytric-context/CytricContextSelector";
import {
  resetCytricContext,
  updateCytricContext,
} from "../../store/cytric-context/CytricContextSlice";
import { loadCytricUrl } from "../../store/redirection/RedirectionActions";
import {
  errorInformation,
  selectCytricUrl,
  selectRedirectionIsError,
  selectRedirectionIsPending,
} from "../../store/redirection/RedirectionSelector";
import { updateCytricUrl } from "../../store/redirection/RedirectionSlice";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import GetAppRoot from "../../utils/GetAppRoot";
import {
  CP_FLOW_IDENTIFIER,
  FeatureToggleDefinition,
  HOTEL_STOP_ON_START_PAGE,
  HOTEL_STOP_ON_START_PAGE_OBSOLETE,
} from "../../utils/constants";
import HostSettings from "../../utils/host.settings";
import {
  submitHandlerForSharingFromClassic,
  submitHandlerForTAJoinATrip,
} from "../join-a-trip/functions/submit-handler";
import { decodeIfSharedFromCytric } from "../share-from-classic/functions/utils";
import CytricComponent from "./CytricComponent";
import "./PersonalTab.scss";

function PersonalTab({ hasTARole }: { hasTARole?: boolean }) {
  const sessionExpiredPageName = "page-session-Expired";
  const viewExpenseInCytric = "viewExpenseInCytric";
  const viewExpenseEventName = "expenses-notification-approverSeeStatement";

  const pagesDisplayedAfterLogout = [
    "page-session-Logout",
    "page-portal-PortalPublic",
    "page-session-Login",
  ];

  const dashboardInput: RedirectionInput = {
    segmentType: RedirectionTypeEnum.DASHBOARD,
  };

  const history = useHistory();
  const [isCytricSessionExpired, setIsCytricSessionExpired] = useState(false);
  const [isCytricLoaded, setIsCytricLoaded] = useState(false);
  const [breadCrumbType, setBreadCrumbType] = useState("");

  const cytricUrl: string = useAppSelector((state) => selectCytricUrl(state));

  const errorInfo: IError = useAppSelector((state) => errorInformation(state));
  const redirectionIsPending: boolean = useAppSelector((state) =>
    selectRedirectionIsPending(state)
  );
  const redirectionIsError: boolean = useAppSelector((state) =>
    selectRedirectionIsError(state)
  );
  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const lastUrlVisited: string = useAppSelector((state) =>
    selectLastVisitedUrl(state)
  );

  const isSessionExpired: boolean = useAppSelector(
    (state) => selectLastUrlDateTimeout(state) < new Date().getTime()
  );

  const featureJoinHotelActivated = CheckFeatureToggle(
    FeatureToggleDefinition.JoinHotel.id
  );

  const featureHideSharedMessageForBotBookings = CheckFeatureToggle(
    FeatureToggleDefinition.hideSharedTripMessageForEasyBotBookButton.id
  );

  const { t } = useTranslation();

  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPPersonalTab.id
  );

  const isFromTrustedOriginUrl = (url: string) => {
    const trustedOrigins = HostSettings.iFrameTrustedOrigins;
    return (
      trustedOrigins.length > 0 &&
      trustedOrigins.some((pattern) => url.match(pattern) != null)
    );
  };

  const isFromTrustedOrigin = (event: MessageEvent<any>) =>
    isFromTrustedOriginUrl(event.origin);

  const isValidUrl = (urlString: string) => {
    try {
      const url = new URL(urlString);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  };

  const refreshActionButton = () => {
    setIsCytricSessionExpired(false);
    Store.dispatch(resetCytricContext());
    Store.dispatch(loadCytricUrl(dashboardInput));
  };

  const containsRequiredData = (event: MessageEvent<any>) =>
    event.data &&
    event.data.currentUrl &&
    event.data.currentUrl.length > 0 &&
    isValidUrl(event.data.currentUrl);

  const containsNotificationData = (event: MessageEvent<any>) =>
    event.data &&
    event.data.url &&
    event.data.url.length > 0 &&
    isValidUrl(event.data.url);

  const DisplaySharePopup = (deepLinkData: any) => {
    const appRoot = GetAppRoot();

    const popupData = JSON.stringify(deepLinkData);

    let taskModuleInfo: BotUrlDialogInfo;

    if (deepLinkData.segmentType === RedirectionTypeEnum.CHAT_TRAVELER) {
      taskModuleInfo = {
        title: t("chatWithTraveler.title"),
        url: `${appRoot}/index.html#/open-chat?${popupData}`,
        size: {
          width: 600,
          height: 600,
        },
        completionBotId: HostSettings.getBotId,
      };
      dialog.url.bot.open(taskModuleInfo);
    } else if (
      deepLinkData.segmentType === RedirectionTypeEnum.SHARE_CONFIRMED_BOOKING
    ) {
      taskModuleInfo = {
        title: t("shareTrip.shareTripTaskModuleSubTitleSharingForOtherUser"),
        url: `${appRoot}/index.html#/share-join?origin=${CP_FLOW_IDENTIFIER}&tripId=${deepLinkData.tripId}`,
        completionBotId: HostSettings.getBotId,
        size: {
          width: 600,
          height: 600,
        },
      };
      dialog.url.bot.open(taskModuleInfo, submitHandlerForSharingFromClassic);
    } else {
      taskModuleInfo = {
        title: t("shareTrip.shareTripTaskModuleSubTitle"),
        url: `${appRoot}/index.html#/share-from-classic?${popupData}`,
        size: {
          width: 600,
          height: 600,
        },
        completionBotId: HostSettings.getBotId,
      };
      dialog.url.bot.open(taskModuleInfo, submitHandlerForSharingFromClassic);
    }
  };

  const displayMatchMyTripPopup = (deepLinkData: any) => {
    const appRoot = GetAppRoot();

    const subEntityId = { subEntityId: deepLinkData };
    const popupData = JSON.stringify(subEntityId);

    const taskModuleInfo: BotUrlDialogInfo = {
      title: t("shareJoin.taskModuleSubtitle"),
      url: `${appRoot}/index.html#/match-my-trip?${popupData}`,
      size: {
        width: 600,
        height: 270,
      },
      completionBotId: HostSettings.getBotId,
    };

    dialog.url.open(taskModuleInfo, submitHandlerForTAJoinATrip);
  };

  const displayJoinATripPopup = (deepLinkData: any) => {
    const appRoot = GetAppRoot();

    // Sharing from Confirmation Page still has double encoding of `"` (`\"`), so we don't need to parse it again"
    const popupData =
      typeof deepLinkData === "string"
        ? deepLinkData
        : JSON.stringify(deepLinkData);

    const taskModuleInfo: BotUrlDialogInfo = {
      title: t("shareJoin.taskModuleSubtitle"),
      url: `${appRoot}/index.html#/share-join-ta?${popupData}`,
      size: {
        width: 600,
        height: 600,
      },
      completionBotId: HostSettings.getBotId,
    };

    dialog.url.open(taskModuleInfo, submitHandlerForTAJoinATrip);
  };

  const shouldRedirectToTravelTab = (dataUrl: string): boolean =>
    /NotifyCloseCollaborators/.test(dataUrl);

  const handleRedirectionFromCytric = (event: MessageEvent<any>) => {
    const matches = event.data.url.match(/context=([^&]*)/);
    if (matches && matches.length >= 2) {
      const decodedContextData = decodeURIComponent(matches[1]);
      const parsedContext = JSON.parse(decodedContextData);
      const subEntityId = decodeIfSharedFromCytric(parsedContext.subEntityId);
      if (shouldRedirectToTravelTab(subEntityId)) {
        app.openLink(event.data.url);
      } else {
        DisplaySharePopup(JSON.parse(subEntityId));
      }
    }
  };

  const handleInteractionsInCytric = (event: MessageEvent<any>) => {
    setIsCytricLoaded(true);
    const url = event.data.currentUrl;
    const pageName = event.data.currentPage;

    if (sessionExpiredPageName === pageName) {
      setIsCytricSessionExpired(true);
      Store.dispatch(resetCytricContext());
      return;
    }

    if (pagesDisplayedAfterLogout.includes(pageName)) {
      doLogoutFromTravelTab();

      history.push("/travel");
      return;
    }

    Store.dispatch(
      updateCytricContext({
        url,
        pageName,
      })
    );
  };

  const postMessageListener = (event: MessageEvent<any>) => {
    if (
      containsRequiredData(event) &&
      (isFromTrustedOrigin(event) || event.isTrusted)
    ) {
      handleInteractionsInCytric(event);
    }

    if (
      containsNotificationData(event) &&
      (isFromTrustedOrigin(event) || event.isTrusted)
    ) {
      handleRedirectionFromCytric(event);
    }
  };

  const isRedirectionType = (segmentType: RedirectionTypeEnum): boolean =>
    segmentType === RedirectionTypeEnum.SHARE_HOTEL ||
    segmentType === RedirectionTypeEnum.SHARE_FLIGHT ||
    segmentType === RedirectionTypeEnum.SHARE_RAIL ||
    segmentType === RedirectionTypeEnum.SHARE_CAR ||
    segmentType === RedirectionTypeEnum.SHARE_CONFIRMED_BOOKING ||
    segmentType === RedirectionTypeEnum.CHAT_TRAVELER;

  const isBookFlightRedirectionType = (
    segmentType: RedirectionTypeEnum
  ): boolean => segmentType === RedirectionTypeEnum.FLIGHT;

  const isDeprecatedMatchMyTrip = (subEntityId: any) =>
    subEntityId.deprecatedMMT === true;

  const isBookRedirectionType = (segmentType: RedirectionTypeEnum): boolean =>
    segmentType === RedirectionTypeEnum.HOTEL ||
    segmentType === RedirectionTypeEnum.FLIGHT ||
    segmentType === RedirectionTypeEnum.RAIL ||
    segmentType === RedirectionTypeEnum.CAR;

  const displayCorrectCytricUrl = () => {
    if (lastUrlVisited && !isSessionExpired) {
      Store.dispatch(updateCytricUrl({ url: lastUrlVisited }));
    } else {
      Store.dispatch(resetCytricContext());
      Store.dispatch(loadCytricUrl(dashboardInput));
    }
  };

  const amend = (params: any) => {
    const amendedParams = params;
    if (amendedParams.segmentType === RedirectionTypeEnum.HOTEL) {
      amendedParams.name = featureJoinHotelActivated
        ? amendedParams.name
        : undefined;
      amendedParams.stopOnStartPage = featureJoinHotelActivated
        ? HOTEL_STOP_ON_START_PAGE
        : HOTEL_STOP_ON_START_PAGE_OBSOLETE;
    }

    return amendedParams;
  };

  const trackExpenseEvent = (params: any) => {
    if (
      params.segmentType === RedirectionTypeEnum.EXPENSE_DASHBOARD &&
      params.source === viewExpenseInCytric
    ) {
      const properties = {
        statementId: params.expenseId,
      };

      telemetryService.trackEvent({
        name: viewExpenseEventName,
        properties,
      });
    }
  };

  const handleRedirectionType = (amendedEntityJson: any) => {
    DisplaySharePopup(amendedEntityJson);
    displayCorrectCytricUrl();
  };

  const handleFeatureArrangerJoinATrip = (amendedEntityJson: any) => {
    if (isDeprecatedMatchMyTrip(amendedEntityJson)) {
      Store.dispatch(loadCytricUrl(amendedEntityJson));
    } else if (hasTARole) {
      if (isBookRedirectionType(amendedEntityJson.segmentType)) {
        displayJoinATripPopup(amendedEntityJson);
      } else {
        Store.dispatch(loadCytricUrl(amendedEntityJson));
      }
    } else if (isBookFlightRedirectionType(amendedEntityJson.segmentType)) {
      if (
        featureHideSharedMessageForBotBookings &&
        TripHelper.isRedirectionFromEasyBotBookButton(
          amendedEntityJson.buttonType
        )
      ) {
        Store.dispatch(loadCytricUrl(amendedEntityJson));
      } else {
        displayMatchMyTripPopup(amendedEntityJson);
      }
    } else {
      Store.dispatch(loadCytricUrl(amendedEntityJson));
    }
  };

  const handleAmendedEntityJson = (amendedEntityJson: any) => {
    // Prevents the popup (dialog) from opening again
    // this is due to inability to reset context.app.page.subPageId
    // other way than with app.openLink()
    if (amendedEntityJson.dialogHandled === true) {
      Store.dispatch(loadCytricUrl(amendedEntityJson));
      return;
    }

    if (isRedirectionType(amendedEntityJson.segmentType)) {
      handleRedirectionType(amendedEntityJson);
    } else {
      handleFeatureArrangerJoinATrip(amendedEntityJson);
    }
  };

  const handleSubEntityId = (subEntityId: any) => {
    try {
      const entityJson = JSON.parse(subEntityId);
      const amendedEntityJson = amend(entityJson);
      handleAmendedEntityJson(amendedEntityJson);
      trackExpenseEvent(entityJson);
    } catch (error) {
      displayCorrectCytricUrl();
    }
  };

  const controlContext = () => {
    app.getContext().then((context: app.Context) => {
      const subPageId = context?.page?.subPageId;
      if (subPageId) {
        const subEntityId = decodeIfSharedFromCytric(subPageId);
        handleSubEntityId(subEntityId);
      } else {
        displayCorrectCytricUrl();
      }
    });
  };

  function genericInfo() {
    const errorRequest: IGenericInfo = {
      title: t(`shareJoin.errorRequest.title`),
      detail: t(`shareJoin.errorRequest.detail`),
      track: TrackError.TRAVEL_WORKFLOW_ERROR_VIEW,
    };

    const action: IGenericInfoAction = {
      text: t("shareJoin.errorRequest.planNewTrip"),
      command: () => {
        telemetryService.trackEvent({
          name: "travel-errorRequestWorkflow-planNewTrip",
        });
        const deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
        app.openLink(deepLink);
      },
    };

    return (
      <GenericInfo
        infoData={errorRequest}
        image={InfoImage.ERROR_REQUEST}
        action={action}
      />
    );
  }

  const handleUrlParams = (urlParams: URLSearchParams) => {
    const type = urlParams.get("type");
    if (type) {
      setBreadCrumbType(type);
      const ctxt = urlParams.get("context") || "";
      const entityJson = JSON.parse(decodeURIComponent(ctxt));
      const amendedEntityJson = amend(entityJson);
      handleAmendedEntityJson(amendedEntityJson);
    } else {
      const sessionType = urlParams.get("sessionType");
      if (sessionType) {
        setBreadCrumbType(sessionType);
      }
      controlContext();
    }
  };

  // use effect on load
  useEffect(() => {
    window.addEventListener("message", postMessageListener, false);
    if (isLoggedIn) {
      const currentUrl = window.location.href;
      const urlParamStr = currentUrl.split("?")[1];
      const urlParams = new URLSearchParams(urlParamStr);
      if (urlParams && Array.from(urlParams).length > 0) {
        handleUrlParams(urlParams);
      } else {
        controlContext();
      }
      telemetryService.trackPageView({ name: "cytric-web" });
    }
    // cleanup this component
    return () => {
      window.removeEventListener("message", postMessageListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const actionRefresh: IGenericInfoAction = {
    text: t("PersonalTab.Refresh"),
    command: refreshActionButton,
  };

  useEffect(() => {
    if (isTRIPPUser && isFromTrustedOriginUrl(cytricUrl)) {
      setIsCytricLoaded(true);
    }
  }, [cytricUrl, isTRIPPUser]);

  const refreshInfo: IGenericInfo = {
    title: t("PersonalTab.Cytric_session_expired_title"),
    detail: t("PersonalTab.Cytric_session_expired_detail"),
  };

  if (isCytricSessionExpired) {
    return (
      <GenericInfo
        infoData={refreshInfo}
        action={actionRefresh}
        image={InfoImage.SESSION_EXPIRED}
      />
    );
  }

  const loaderComponent = (
    <Loader
      size="large"
      className="trip-loader"
      data-testid="loadingSpinner"
      label={t("spinner.waitAMoment")}
    />
  );

  const iframe = (
    <iframe
      className={isCytricLoaded ? "cytric-iframe" : "cytric-iframe-none"}
      id="dynamic-tab"
      title="cytric"
      src={cytricUrl}
      scrolling="true"
      sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-modals allow-presentation allow-same-origin allow-downloads allow-popups-to-escape-sandbox"
    />
  );

  // This code is returned in Response @errorInfo when redirection request was for some Booking service (Hotel, Flight, etc..)
  const ERROR_REQUEST_WORKFLOW = 120;
  if (redirectionIsError) {
    if (errorInfo.code === ERROR_REQUEST_WORKFLOW) {
      return genericInfo();
    }

    return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
  }

  return (
    <Flex
      className="personal-tab-container"
      id="personal-tab-container"
      data-testid="personal-tab-container"
    >
      <CytricComponent
        breadCrumbType={breadCrumbType}
        redirectionIsPending={redirectionIsPending}
        isCytricLoaded={isCytricLoaded}
        loaderComponent={loaderComponent}
        iframe={iframe}
      />
    </Flex>
  );
}

PersonalTab.defaultProps = {
  hasTARole: false,
};

export default PersonalTab;
