import {
  amadeusCytricLogin,
  amadeusCytricPinSecLogin,
  TeamsUserCredential,
  UNAUTHORIZED_ERROR_CODE,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "../../i18n/i18n";
import AuthenticationService from "../../services/authentication/authentication.service";
import {
  CYTRIC_EASY_USER_DID_LOGIN,
  CYTRIC_EASY_USER_DID_LOGOUT,
  CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT,
  EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY,
  USER_LOG_OUT_FLAG,
} from "../../utils/constants";
import HostSettings from "../../utils/host.settings";

export const AUTHENTICATION_STORE_NAME = "authentication";
export const AUTHENTICATION_LOGIN = "login";
export const AUTHENTICATION_LOGOUT = "logout";
export const AUTHORIZATION_CHECK = "token";

export const login = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGIN}`,
  async (data, { rejectWithValue }) => {
    if (HostSettings.isCytricLoginEnabled) {
      try {
        await amadeusCytricLogin(
          HostSettings.getKeycloakUri,
          HostSettings.getKeycloakAuthUri,
          i18n.language
        );
        sessionStorage.setItem(USER_LOG_OUT_FLAG, CYTRIC_EASY_USER_DID_LOGIN);
      } catch (error: any) {
        sessionStorage.removeItem(USER_LOG_OUT_FLAG);
        return rejectWithValue(
          error.message ? `messages.${error.message}` : `messages.ERR_Teams`
        );
      }
    }

    return true;
  }
);

export const loginForcePinSec = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGIN}`,
  async (_, { rejectWithValue }) => {
    if (HostSettings.isCytricLoginEnabled) {
      try {
        await amadeusCytricPinSecLogin(
          HostSettings.getKeycloakUri,
          HostSettings.getKeycloakAuthUri,
          i18n.language
        );
        sessionStorage.setItem(USER_LOG_OUT_FLAG, CYTRIC_EASY_USER_DID_LOGIN);
      } catch (error: any) {
        sessionStorage.removeItem(USER_LOG_OUT_FLAG);
        return rejectWithValue(
          error.message ? `messages.${error.message}` : `messages.ERR_Teams`
        );
      }
    }

    return true;
  }
);

export const logout = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGOUT}`,
  async () => {
    if (HostSettings.isCytricLoginEnabled) {
      await AuthenticationService.logout();
      localStorage.removeItem("KeepSessionCache");
      localStorage.removeItem(EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY);
      sessionStorage.clear();
      sessionStorage.setItem(USER_LOG_OUT_FLAG, CYTRIC_EASY_USER_DID_LOGOUT);
    }
  }
);

export const switchAccount = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGOUT}`,
  async () => {
    if (HostSettings.isCytricLoginEnabled) {
      await AuthenticationService.logout();
      localStorage.removeItem("KeepSessionCache");
      localStorage.removeItem(EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY);
      sessionStorage.clear();
      sessionStorage.setItem(
        USER_LOG_OUT_FLAG,
        CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT
      );
    }
  }
);

export const logoutFromTravelTab = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHENTICATION_LOGOUT}`,
  async () => {
    if (HostSettings.isCytricLoginEnabled) {
      localStorage.removeItem("KeepSessionCache");
      localStorage.removeItem(EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY);
      sessionStorage.clear();
    }
  }
);

/* Pre-check tenant has access to cytric, need a better endpoint for this check */
export const token = createAsyncThunk(
  `${AUTHENTICATION_STORE_NAME}/${AUTHORIZATION_CHECK}`,
  async (data, { rejectWithValue }) => {
    if (HostSettings.isCytricLoginEnabled) {
      try {
        const credential = new TeamsUserCredential();
        await credential.getToken("cytric-easy");
      } catch (error: any) {
        if (
          error?.response === undefined &&
          error?.message.includes(`${UNAUTHORIZED_ERROR_CODE}`)
        ) {
          return rejectWithValue("unauthorized");
        }

        return rejectWithValue(
          error.message ? `${error.message}` : `messages.ERR_Teams`
        );
      }
    }

    return false;
  }
);

export const didUserLogOut = () =>
  sessionStorage.getItem(USER_LOG_OUT_FLAG) === CYTRIC_EASY_USER_DID_LOGOUT ||
  sessionStorage.getItem(USER_LOG_OUT_FLAG) ===
    CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT;
