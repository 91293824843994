import AirRedirectionInput from "../common/models/redirection/AirRedirectionInput";
import AirRedirectionParam from "../common/models/redirection/AirRedirectionParam";
import CarRedirectionInput from "../common/models/redirection/CarRedirectionInput";
import CarRedirectionParam from "../common/models/redirection/CarRedirectionParam";
import ConfirmationPageRedirectionInput from "../common/models/redirection/ConfirmationPageRedirectionInput";
import ExpenseRedirectionInput from "../common/models/redirection/ExpenseRedirectionInput";
import ExpenseRedirectionParam from "../common/models/redirection/ExpenseRedirectionParam";
import HotelRedirectionInput from "../common/models/redirection/HotelRedirectionInput";
import HotelRedirectionParam from "../common/models/redirection/HotelRedirectionParam";
import RailRedirectionInput from "../common/models/redirection/RailRedirectionInput";
import RailRedirectionParam from "../common/models/redirection/RailRedirectionParam";
import TripApprovalRedirectionInput from "../common/models/redirection/TripApprovalRedirectionInput";
import TripSegmentInput from "../common/models/redirection/TripSegmentInput";
import DateHelper from "./DateHelper";
import trackRedirection from "./TrackRedirectionHelper";

type RedirectionParam =
  | AirRedirectionParam
  | CarRedirectionParam
  | ExpenseRedirectionParam
  | HotelRedirectionParam
  | RailRedirectionParam;

export default class RedirectionParamaterHelper {
  public static transformAirRedirectionInput(
    airRedirectionInput: AirRedirectionInput
  ): AirRedirectionParam {
    const airParam: AirRedirectionParam = {};

    trackRedirection(airRedirectionInput);

    // TODO: Should we share smartSearchId, timeWindowInHours and basisCode from Cytric Classic also for TA?

    // Explicitly set to false if not provided
    airParam.directFlights = airRedirectionInput.directFlights || false;
    airParam.stopOnStartPage = airRedirectionInput.stopOnStartPage || false;

    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "numberOfPassengers",
      airRedirectionInput.numberOfPassengers
    );

    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "departureLocationCode",
      airRedirectionInput.departure
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "arrivalLocationCode",
      airRedirectionInput.arrival
    );

    if (airRedirectionInput.dateTime) {
      airParam.dateTime = airRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }

    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "tripPurpose",
      airRedirectionInput.purpose
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "airlineCode",
      airRedirectionInput.airlineCode
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "airlineNumber",
      airRedirectionInput.airlineNumber
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "smartSearchId",
      airRedirectionInput.smartSearchId
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "basisCode",
      airRedirectionInput.basisCode
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "dateTimeType",
      airRedirectionInput.dateTimeType
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "timeWindowInHours",
      airRedirectionInput.timeWindowInHours
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "numberOfFlights",
      airRedirectionInput.numberOfFlights
    );

    // TODO: Double-check this for Traveler vs Travel Arranger
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "departureLocationCode",
      airRedirectionInput.departureLocationCode
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "arrivalLocationCode",
      airRedirectionInput.arrivalLocationCode
    );

    RedirectionParamaterHelper.copyTruthyAttribute(
      airParam,
      "isSharedTrip",
      airRedirectionInput.isSharedTrip
    );

    // For Traveler Arranger case, if travelerUserId is provided, add it to the airParams
    const { travelerUserId } = airRedirectionInput;
    if (travelerUserId && travelerUserId.length > 0) {
      airParam.travelerUserId = travelerUserId;
    }

    return airParam;
  }

  public static transformCarRedirectionInput(
    carRedirectionInput: CarRedirectionInput
  ): CarRedirectionParam {
    const carParam: CarRedirectionParam = {};
    trackRedirection(carRedirectionInput);

    // TODO: Should we share bookingService, carOfficeCode, latitude and longitude from Cytric Classic also for TA?

    // Explicitly set to false if not provided
    carParam.carDelivery = carRedirectionInput.carDelivery || false;
    carParam.carCollection = carRedirectionInput.carCollection || false;
    carParam.stopOnStartPage = carRedirectionInput.stopOnStartPage || false;

    // Mapping carInput to carParam

    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "carOfficeCode",
      carRedirectionInput.carOfficeCode
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "locationName",
      carRedirectionInput.name
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "latitude",
      carRedirectionInput.latitude
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "longitude",
      carRedirectionInput.longitude
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "locationCode",
      carRedirectionInput.code
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "countryCode",
      carRedirectionInput.country
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "tripPurpose",
      carRedirectionInput.purpose
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      carParam,
      "isSharedTrip",
      carRedirectionInput.isSharedTrip
    );

    // Formatting dateTime
    if (carRedirectionInput.dateTime) {
      carParam.dateTime = carRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }

    // Special case for chauffeur and taxi
    if (carRedirectionInput.isChauffeurAndTaxi) {
      carParam.bookingService = "CAR_BOOKING_TAXI";
    }

    // For Traveler Arranger case, if travelerUserId is provided, add it to the carParams
    const { travelerUserId } = carRedirectionInput;
    if (travelerUserId && travelerUserId.length > 0) {
      carParam.travelerUserId = travelerUserId;
    }

    return carParam;
  }

  public static transformHotelRedirectionInput(
    hotelRedirectionInput: HotelRedirectionInput
  ): HotelRedirectionParam {
    trackRedirection(hotelRedirectionInput);
    const keyMap: { [key: string]: keyof HotelRedirectionParam } = {
      // RedirectionInput key: RedirectionParam key
      locationCode: "locationCode",
      locationName: "locationName",
      departure: "checkOutDate",
      arrival: "checkInDate",
      name: "name",
      latitude: "latitude",
      longitude: "longitude",
      purpose: "tripPurpose",
      stopOnStartPage: "stopOnStartPage",
      isSharedTrip: "isSharedTrip",
    };
    const hotelParam: HotelRedirectionParam = Object.keys(keyMap).reduce(
      (acc: HotelRedirectionParam, key) => {
        const inputKey = key as keyof HotelRedirectionInput;
        const paramKey = keyMap[key];
        if (hotelRedirectionInput[inputKey]) {
          acc[paramKey] = hotelRedirectionInput[inputKey];
        }
        return acc;
      },
      {} as HotelRedirectionParam
    );

    // For Traveler Arranger case, if travelerUserId is provided, add it to the hotelParams
    const { travelerUserId } = hotelRedirectionInput;
    if (travelerUserId && travelerUserId.length > 0) {
      hotelParam.travelerUserId = travelerUserId;
    }
    // Explicitly set to false if not provided
    hotelParam.stopOnStartPage = hotelRedirectionInput.stopOnStartPage || false;
    return hotelParam;
  }

  public static transformRailRedirectionInput(
    railRedirectionInput: RailRedirectionInput
  ): RailRedirectionParam {
    const railParam: RailRedirectionParam = {};

    // TODO: Cytric Classic for TA - should we share also departureLocationName and arrivalLocationName?
    // TODO: Cytric Classic for TA - what is the serviceClass? should we share providerCode instead?

    trackRedirection(railRedirectionInput);

    // Explicitly set to false if not provided
    railParam.directTrains = railRedirectionInput.directTrains || false;
    railParam.stopOnStartPage = railRedirectionInput.stopOnStartPage || false;

    if (railRedirectionInput.dateTime) {
      railParam.dateTime = railRedirectionInput.dateTime
        .filter((val) => val)
        .map((datetime) =>
          DateHelper.formatDateTimeToRedirectionDateTime(datetime)
        );
    }

    RedirectionParamaterHelper.copyTruthyAttribute(
      railParam,
      "tripPurpose",
      railRedirectionInput.purpose
    );

    const departureCodeInput = railRedirectionInput.departureCode;
    const arrivalCodeInput = railRedirectionInput.arrivalCode;
    const providerCodeInput = railRedirectionInput.providerCode;
    if (
      (departureCodeInput && departureCodeInput.length > 0) ||
      (arrivalCodeInput && arrivalCodeInput.length > 0) ||
      (providerCodeInput && providerCodeInput.length > 0)
    ) {
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "departureLocationName",
        railRedirectionInput.departure
      );
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "departureLocationCode",
        railRedirectionInput.departureCode
      );
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "arrivalLocationName",
        railRedirectionInput.arrival
      );
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "arrivalLocationCode",
        railRedirectionInput.arrivalCode
      );
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "providerCode",
        railRedirectionInput.providerCode
      );
    } else {
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "departureLocationCode",
        railRedirectionInput.departure
      );
      RedirectionParamaterHelper.copyTruthyAttribute(
        railParam,
        "arrivalLocationCode",
        railRedirectionInput.arrival
      );
    }

    RedirectionParamaterHelper.copyTruthyAttribute(
      railParam,
      "departureLocationCode",
      railRedirectionInput.departureLocationCode
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      railParam,
      "arrivalLocationCode",
      railRedirectionInput.arrivalLocationCode
    );

    RedirectionParamaterHelper.copyTruthyAttribute(
      railParam,
      "isSharedTrip",
      railRedirectionInput.isSharedTrip
    );

    // For Traveler Arranger case, if travelerUserId is provided, add it to the railParams
    const { travelerUserId } = railRedirectionInput;
    if (travelerUserId && travelerUserId.length > 0) {
      railParam.travelerUserId = travelerUserId;
    }

    return railParam;
  }

  public static transformExpenseRedirectionInput(
    expenseRedirectionInput: ExpenseRedirectionInput
  ): ExpenseRedirectionParam {
    const expenseParam: ExpenseRedirectionParam = {};

    RedirectionParamaterHelper.copyTruthyAttribute(
      expenseParam,
      "expenseStatementId",
      expenseRedirectionInput.expenseId
    );
    RedirectionParamaterHelper.copyTruthyAttribute(
      expenseParam,
      "userRole",
      expenseRedirectionInput.userRole
    );

    return expenseParam;
  }

  public static transformRailCardRedirectionInput(
    railCardRedirectionInput: any
  ): any {
    return railCardRedirectionInput.railCompany;
  }

  public static transformCarCardRedirectionInput(
    carCardRedirectionInput: any
  ): any {
    const carParam: CarRedirectionParam = {};

    if (carCardRedirectionInput.bookingService) {
      carParam.bookingService = carCardRedirectionInput.bookingService;
    }
    if (carCardRedirectionInput.stopOnStartPage) {
      carParam.stopOnStartPage = carCardRedirectionInput.stopOnStartPage;
    }

    return carParam;
  }

  public static transformFlightCardRedirectionInput(
    flightCardRedirectionInput: AirRedirectionInput
  ): any {
    const flightParam: AirRedirectionParam = {};
    if (flightCardRedirectionInput.stopOnStartPage) {
      flightParam.stopOnStartPage = flightCardRedirectionInput.stopOnStartPage;
    }
    return flightParam;
  }

  public static transformConfirmationPageRedirectionInput(
    flightCardRedirectionInput: ConfirmationPageRedirectionInput
  ): string {
    return flightCardRedirectionInput.tripId;
  }

  public static transformAddFlightRedirectionInput(
    addFlightRedirectionInput: ConfirmationPageRedirectionInput
  ): string {
    return addFlightRedirectionInput.tripId;
  }

  public static transformAddHotelRedirectionInput(
    addHotelRedirectionInput: ConfirmationPageRedirectionInput
  ): string {
    return addHotelRedirectionInput.tripId;
  }

  public static transformAddRailRedirectionInput(
    addRailRedirectionInput: ConfirmationPageRedirectionInput
  ): string {
    return addRailRedirectionInput.tripId;
  }

  public static transformAddCarRedirectionInput(
    addCarRedirectionInput: TripSegmentInput
  ): TripSegmentInput {
    return addCarRedirectionInput;
  }

  public static transformAddTransferRedirectionInput(
    addTransferRedirectionInput: TripSegmentInput
  ): TripSegmentInput {
    return addTransferRedirectionInput;
  }

  public static transformTripApprovalRedirectionInput(
    flightCardRedirectionInput: TripApprovalRedirectionInput
  ): TripApprovalRedirectionInput {
    return flightCardRedirectionInput;
  }

  /* eslint no-param-reassign: ["error", { "props": false }] */
  private static copyTruthyAttribute<
    T extends RedirectionParam,
    K extends keyof T,
  >(obj: T, prop: K, val: T[K]): void {
    if (val) {
      obj[prop] = val;
    }
  }
}
